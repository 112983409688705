import { useMutation } from '@apollo/client';
import Title from '@atoms/Title';
import { Box, Button, Stack } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import { CREATE_NOTICE, SEARCH_NOTICES, makeNoticeVariables } from '@graphql/notice';
import useModal from '@hooks/useModal';
import useResetNotice from '@hooks/useResetNotice';
import { BgWrapper, ContentWrapper } from '@layout/index';
import { Paths } from '@pages/Router';
import { perPageFamily } from '@store/family/tableFamily';
import { noticeCreateInputState } from '@store/noticeState';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import NoticeWriter from './NoticeWriter';

function CreateNotice() {
  const navigator = useNavigate();

  const noticeCreateInput = useRecoilValue(noticeCreateInputState);
  const resetNoticeState = useResetNotice();
  const perPage = useRecoilValue(perPageFamily('notice'));
  const { openModal } = useModal();

  const [createNotice, { error }] = useMutation(CREATE_NOTICE, {
    variables: {
      input: noticeCreateInput,
    },
    refetchQueries: [
      {
        query: SEARCH_NOTICES,
        variables: makeNoticeVariables({
          listLocale: 'ALL',
          isActive: 'ALL',
          currentPage: 1,
          perPage,
          selectedSearchOption: 'OR',
          keyword: '',
        }),
      },
    ],
    onCompleted: ({ result }) => {
      alert('저장되었습니다.');
      resetNoticeState();
      navigator(Paths.NoticeBoard);
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '저장에 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          resetNoticeState();
          navigator(Paths.NoticeBoard);
        },
      });
    },
  });

  const handleSubmitClick = () => {
    if (noticeCreateInput.subject.length === 0 || noticeCreateInput.content.length === 0) {
      alert('제목 및 내용을 입력하세요.');
      return;
    }
    createNotice();
  };

  const onSubmitClick = () => {
    openModal({
      type: MODAL_TYPES.confirm,
      title: '저장',
      message: '공지사항을 등록하시겠습니까?',
      confirm: () => {
        handleSubmitClick();
      },
    });
  };

  const onSubmitCancelClick = () => {
    openModal({
      type: MODAL_TYPES.alert,
      title: '취소',
      message: '등록을 취소하였습니다.',
      alert: () => {
        navigator(Paths.NoticeBoard);
        resetNoticeState();
      },
    });
  };

  if (error) {
    return <div></div>;
  }

  return (
    <Box px={8} minW="900px">
      <Title title="공지사항 등록" />
      <ContentWrapper>
        <BgWrapper style={{ paddingBottom: '4rem' }}>
          <NoticeWriter />
        </BgWrapper>
      </ContentWrapper>
      <Stack direction="row" spacing={4} justifyContent="end" mb={5}>
        <Button colorScheme="teal" onClick={onSubmitCancelClick}>
          취소
        </Button>
        <Button colorScheme="teal" onClick={onSubmitClick}>
          저장
        </Button>
      </Stack>
    </Box>
  );
}
export default CreateNotice;
