import Title from '@atoms/Title';
import { Box } from '@chakra-ui/react';
import { BgWrapper } from '@layout/index';
import { useEffect, useRef, useState } from 'react';
import FaqFilter from './components/FaqFilter';
import FaqList from './components/FaqList';
import LanguageTab from '@articles/LanguageTab';
import {
  keywordState,
  selectedLanguageOptionState,
  selectedSearchOptionState,
  useSearchFaqs,
} from '@store/faqState';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { makeFaqVariables } from '@graphql/faq';
import { getLanguageValueByKey } from '@utils/index';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@pages/Router';
import { LanguageOption } from '@/types/index';
import { currentPageFamily } from '@store/family/tableFamily';
import { isListButtonClickedFamily } from '@store/family/navigationFamily';

function FaqBoard() {
  const navigate = useNavigate();
  const isFirstMount = useRef(true);
  const [isEditingOrder, setIsEditingOrder] = useState(false);

  const setCurrentPage = useSetRecoilState(currentPageFamily('faq'));
  const [isListButtonClicked, setIsListButtonClicked] = useRecoilState(
    isListButtonClickedFamily(Paths.FaqBoard),
  );
  const [selectedLanguageOption, setSelectedLanguageOption] = useRecoilState(
    selectedLanguageOptionState,
  );
  const selectedLanguageValue = getLanguageValueByKey(selectedLanguageOption);
  const setSelectedSearchOption = useSetRecoilState(selectedSearchOptionState);
  const setKeyword = useSetRecoilState(keywordState);

  const { searchFaqs } = useSearchFaqs();

  const handleEditOrderClick = () => {
    setIsEditingOrder((prev: boolean) => !prev);
    setSelectedSearchOption('OR');
    setKeyword('');
  };

  // 언어 변경 시, 검색 필터 해제
  const handleLanguageChange = (value: LanguageOption) => {
    setSelectedLanguageOption(value);
  };

  // 순서편집 모드에서 뒤로가기 시, FaqBoard로 이동
  window.onpopstate = () => {
    if (isEditingOrder) {
      navigate(Paths.FaqBoard);
      setIsEditingOrder(false);
    } else {
      window.history.back();
    }
  };

  // 언어 선택 변경 시, 해당 언어 리스트 데이터 받아오기
  useEffect(() => {
    // 목록 버튼 통해 리스트 접속한 경우, 검색 필터 및 페이지네이션 초기화 방지
    if (isFirstMount.current && isListButtonClicked) {
      setIsListButtonClicked(false);
      return;
    }

    // 나머지의 경우에는 검색 필터 및 리스트 데이터 초기화
    setSelectedSearchOption('OR');
    setKeyword('');
    setCurrentPage(1);

    searchFaqs({
      variables: makeFaqVariables({
        listLocale: selectedLanguageOption,
        currentPage: 1,
        selectedSearchOption: 'OR',
        perPage: 10,
        keyword: '',
      }),
    });
  }, [selectedLanguageOption]);

  return (
    <Box px={8} minW="900px" mb={10}>
      <Title
        title={`자주 하는 질문 (${selectedLanguageValue}) ${isEditingOrder ? '순서 편집' : ''}`}
      />
      <BgWrapper mt={10}>
        {!isEditingOrder && (
          <>
            <LanguageTab defaultLanguage={selectedLanguageOption} onChange={handleLanguageChange} />
            <FaqFilter onEditOrderClick={handleEditOrderClick} />
          </>
        )}
        <FaqList isEditingOrder={isEditingOrder} onEditOrderClick={handleEditOrderClick} />
      </BgWrapper>
    </Box>
  );
}

export default FaqBoard;
