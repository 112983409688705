export const initialPageInfo = {
  count: 1,
  currentPage: 1,
  hasMorePages: false,
  total: 0,
  lastPage: 1,
};

export interface PaginatorInfoType {
  count: number;
  currentPage: number;
  hasMorePages: boolean;
  total: number;
  lastPage: number;
}

export enum FilterType {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
}

export type SelectOption<T> = {
  key: T;
  value: string;
};

export type LanguageOption = 'ALL' | 'KOREAN' | 'ENGLISH';
export type SearchOption = 'OR' | 'SUBJECT' | 'CONTENT';
export type Status = 'ALL' | 'COMPLETED' | 'CANCEL';
export type NoticeStatus = 'ALL' | 'ACTIVE' | 'INACTIVE';

export interface ImportsData {
  order_no: string;
  partner_order_no: string;
  email: string;
  partner_id: string;
  original_amount: number;
  amount: number;
  fee: number;
  swap_status: string;
  acronym: string;
  created_at: string;
  canceled_at: string;
}

export type ImportsSummaryType = {
  total_count: number;
  completed_count: number;
  cancel_count: number;
  export_amount: number;
  import_amount: number;
  fee: number;
};

export interface ExportsData {
  order_no: string;
  partner_order_no: string;
  email: string;
  partner_id: string;
  original_amount: number;
  amount: number;
  fee: number;
  swap_status: string;
  acronym: string;
  reward: number;
  canceled_at: string;
  created_at: string;
}

export type ExportsSummaryType = {
  total_count: number;
  completed_count: number;
  cancel_count: number;
  export_amount: number;
  import_amount: number;
  reward_amount: number;
  fee: number;
};

export interface User {
  id: string;
  uuid: string;
  email: string;
  locale: string;
  pin: string;
  private_key_decryption_syntax: string;
  created_at: Date;
  updated_at: Date;
  roles: [];
  pivot: {};
  userCoinWallets: {};
}

export interface Image {
  id: string;
  url: string;
  thumb_small_url?: string;
  thumb_medium_url?: string;
  thumb_large_url?: string;
}
export interface NoticeType {
  id: string;
  subject: string;
  content: string;
  is_fixed: number;
  is_popup: number;
  is_active: number;
  started_at: string | null;
  ended_at: string | null;
  created_at?: Date;
  updated_at?: Date;
  images: Image[];
  thumbnail?: Image;
  author?: User;
  locale: string;
  previous?: { id: string; subject: string };
  next?: { id: string; subject: string };
}

export interface noticeCreateInputType {
  subject: string;
  content: string;
  is_fixed: number;
  is_popup: number;
  is_active: number;
  started_at: string | null;
  ended_at: string | null;
  images?: number[];
  thumbnail?: number;
  locale: string;
}
export interface noticeUpdateInputType {
  id: string;
  subject: string;
  content: string;
  is_fixed: number;
  is_popup: number;
  is_active: number;
  started_at: string | null;
  ended_at: string | null;
  images?: number[];
  thumbnail?: number;
  locale: string;
  deleteImages: number[];
  deleteThumbnail: number | undefined;
}
export interface NoticeListType {
  data: NoticeType[];
  paginatorInfo: PaginatorInfoType;
}

export interface FaqType {
  id: string;
  subject: string;
  content: string;
  order_by_no: number;
  previous: { id: string };
  next: { id: string };
  created_at?: Date;
  updated_at?: Date;
  images: number[];
  author: User;
  locale: string;
}

export interface FaqCreateInputType {
  subject: string;
  content: string;
  images: number[];
  locale: string;
}

export interface FaqUpdateInputType {
  id: string;
  subject: string;
  content: string;
  images: number[];
  locale: string;
  deleteImages: number[];
}

export interface FaqListType {
  data: FaqType[];
  paginatorInfo: PaginatorInfoType;
}
