import { useMutation } from '@apollo/client';
import QuillEditor from '@articles/QuillEditor';
import Title from '@atoms/Title';
import { Box, Flex, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import { CREATE_FAQ, SEARCH_FAQS, makeFaqVariables } from '@graphql/faq';
import useModal from '@hooks/useModal';
import { BgWrapper, ContentWrapper } from '@layout/index';
import { Paths } from '@pages/Router';
import { selectedLanguageOptionState, useSearchFaqs } from '@store/faqState';
import { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SubmitButtonGroup from './SubmitButtonGroup';

import { getLanguageValueByKey } from '@utils/index';

function CreateFaq() {
  const navigate = useNavigate();
  const { openModal } = useModal();

  const locale = useRecoilValue(selectedLanguageOptionState);
  const localeValue = getLanguageValueByKey(locale);
  const { paginatorInfo } = useSearchFaqs();

  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState<number[]>([]);

  const [createFaq] = useMutation(CREATE_FAQ, {
    onCompleted: ({ result }) => {
      alert('저장되었습니다.');
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '저장에 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          return;
        },
      });
    },
    // 등록된 페이지 (마지막 페이지)로 refetch 전송
    refetchQueries: [
      {
        query: SEARCH_FAQS,
        variables: makeFaqVariables({
          listLocale: locale,
          currentPage: paginatorInfo.lastPage,
          selectedSearchOption: 'OR',
          keyword: '',
          perPage: 10,
        }),
      },
    ],
  });

  const handleSubmitClick = () => {
    if (subject.length === 0 || content.length === 0) {
      openModal({
        type: MODAL_TYPES.alert,
        title: '저장',
        message: '제목 및 내용을 입력하세요.',
        alert: () => {
          return;
        },
      });
    } else {
      openModal({
        type: MODAL_TYPES.confirm,
        title: '저장',
        message: '자주 하는 질문을 등록하시겠습니까?',
        confirm: () => {
          createFaq({
            variables: { input: { subject, content, locale, images } },
          });
        },
      });
    }
  };

  const handleCancelClick = () => {
    openModal({
      type: MODAL_TYPES.alert,
      title: '취소',
      message: '등록을 취소하였습니다.',
      alert: () => {
        navigate(Paths.FaqBoard);
      },
    });
  };

  const handleOnContentChange = (value: string) => {
    setContent(value);
  };

  const handleSetUploadedImages = (value: SetStateAction<number[]>) => {
    setImages(value);
  };

  return (
    <Box px={8} minW="900px">
      <Title title={`자주 하는 질문 등록 (${localeValue})`} />
      <Flex flexDirection={'column'}>
        <ContentWrapper>
          <BgWrapper style={{ paddingBottom: '4rem' }}>
            <Box display="flex" justifyContent={'space-between'}>
              <InputGroup mb={2} width="60%">
                <InputLeftAddon children="제목" />
                <Input
                  type="text"
                  placeholder="제목을 입력하세요."
                  onChange={(e) => setSubject(e.target.value)}
                />
              </InputGroup>
            </Box>
            <QuillEditor
              onChange={handleOnContentChange}
              onImageUpload={handleSetUploadedImages}
              defaultContent=""
            />
          </BgWrapper>
        </ContentWrapper>
        <SubmitButtonGroup onCancelClick={handleCancelClick} onSubmitClick={handleSubmitClick} />
      </Flex>
    </Box>
  );
}

export default CreateFaq;
