import { FaqType } from '@/types/index';
import { ListItem, UnorderedList } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Paths } from '@pages/Router';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { GrDrag } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
interface Props {
  faqList: FaqType[];
  onOrderedIdsChange: (value: string[]) => void;
  isEditingOrder: boolean;
}

const FaqDndList: React.FC<Props> = ({ faqList, onOrderedIdsChange, isEditingOrder }) => {
  const navigate = useNavigate();

  const [orderedFaqList, setOrderedFaqList] = useState<FaqType[]>([]);

  const onDragEnd = ({ source, destination }: DropResult) => {
    const _faqList = [...orderedFaqList];
    // 유효하지 않은 곳으로 drag시 이벤트 종료
    if (!destination) return;

    const [targetFaq] = _faqList.splice(source.index, 1);
    _faqList.splice(destination.index, 0, targetFaq);
    setOrderedFaqList(_faqList);
    onOrderedIdsChange(_faqList.map((item) => item.id));
  };

  useEffect(() => {
    setOrderedFaqList(faqList);
  }, [faqList]);

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <UnorderedList
              listStyleType={'none'}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {orderedFaqList.length > 0 &&
                orderedFaqList.map((faq, idx) => {
                  return (
                    <Draggable
                      draggableId={faq.id}
                      key={faq.id}
                      index={idx}
                      isDragDisabled={!isEditingOrder}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <ListItemContainer
                            isDragging={snapshot.isDragging || snapshot.draggingOver !== null}
                          >
                            <ListItem
                              onClick={() => navigate(`${Paths.Faq.replace(':id', faq.id)}`)}
                            >
                              Q. {faq.subject}
                            </ListItem>
                            {isEditingOrder && <GrDrag />}
                          </ListItemContainer>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </UnorderedList>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

export default FaqDndList;

const Container = styled.div`
  margin: 0 auto;
  width: 70%;
`;

const ListItemContainer = styled.div<{ isDragging: boolean }>`
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  &:hover {
    cursor: pointer;
    border-color: teal;
  }
  width: 80%;
  background-color: ${(props) => (props.isDragging ? 'lightgrey' : '')};
`;
