import { Box } from '@chakra-ui/react';

import Title from '@atoms/Title';
import { BgWrapper } from '@layout/index';
import NoticeFilter from './components/NoticeFilter';
import NoticeTable from './components/NoticeTable';
import {
  selectedStatusOptionState,
  selectedSearchOptionState,
  selectedLanguageOptionState,
  keywordState,
  useSearchNotices,
} from '@store/noticeState';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useEffect, useRef } from 'react';
import { makeNoticeVariables } from '@graphql/notice';
import { isListButtonClickedFamily } from '@store/family/navigationFamily';
import { Paths } from '@pages/Router';

function NoticeBoard() {
  const isFirstMount = useRef(true);

  const [isListButtonClicked, setIsListButtonClicked] = useRecoilState(
    isListButtonClickedFamily(Paths.NoticeBoard),
  );
  const setSelectedSearchOption = useSetRecoilState(selectedSearchOptionState);
  const setSelectedLanguageOption = useSetRecoilState(selectedLanguageOptionState);
  const setSelectedStatusOption = useSetRecoilState(selectedStatusOptionState);
  const setKeyword = useSetRecoilState(keywordState);

  const { searchNotices } = useSearchNotices();

  useEffect(() => {
    if (isFirstMount.current && isListButtonClicked) {
      setIsListButtonClicked(false);
      return;
    }

    setSelectedSearchOption('OR');
    setSelectedLanguageOption('ALL');
    setSelectedStatusOption('ALL');
    setKeyword('');

    searchNotices({
      variables: makeNoticeVariables({
        listLocale: 'ALL',
        currentPage: 1,
        perPage: 10,
        selectedSearchOption: 'OR',
        isActive: 'ALL',
        keyword: '',
      }),
    });
  }, []);

  return (
    <Box minW="900px" mb={10}>
      <Title title="공지사항" />
      <BgWrapper mt={10}>
        <NoticeFilter />
        <NoticeTable />
      </BgWrapper>
    </Box>
  );
}

export default NoticeBoard;
